export const IMAGES = {
    Header: require("./images/Header.png"),
    innovation: require("./images/innovation.png"),
    people: require("./images/people.png"),
    service: require("./images/service.png"),
    Team: require("./images/Team.JPG"),
    akif: require("./images/akif.JPG"),
    abhi: require("./images/abhi.JPG"),
    ved: require("./images/ved.JPG"),
    ceo: require("./images/ceo.JPG"),
    vikas: require("./images/vikas.jpg"),
    sakshi: require("./images/sakshi.jpg"),
    chetna: require("./images/chetna.jpg"),

}